import { ViewContainerRef } from '@angular/core';

export class OAuthIdent {
  public oauth_token: string;
  public dataSource?: string;
  public oauth_verifier?: string;
  public realmId?: string;
  public provider: string;
  public oauth_token_secret;
  public viewRef: ViewContainerRef;
  public redirectPath?: string;
  constructor(parameter?: any) {
    if (parameter) {
      this.dataSource = parameter.dataSource;
      this.oauth_token = parameter.oauth_token;
      this.oauth_verifier = parameter.oauth_verifier;
      this.realmId = parameter.realmId;
    }
  }
}

export class OAuthRequest {
  serviceName: string;
  redirectPath?: string;
  requestToken?: string;
}

export interface AuthCode {
  code: string;
}

export interface SuccessfulAuth {
  access_token: string;
}
